.social{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -19px;
  margin-right: -19px;

  li{
    display: inline-block;
    margin: 0 19px;
  }

  &__icon{
    display: block;
    width: 21px;
    height: 24px;
    text-decoration: none;
    transition: transform $fast-transition;

    svg{
      max-width: 100%;
      max-height: 100%;
      fill: #fff;
    }

    &:focus,
    &:hover{
      transform: scale(1.2);
    }

  }
}