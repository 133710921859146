.section-map{
  position: relative;

  &__map{
    width: 100%;
    height: 350px;
  }

  &__form{
    margin-top: -63px;
    padding-bottom: 32px;
    width: 100%;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
  }


  @include mq(1024){

    .row{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &__map{
      height: 744px;
    }

    &__form{
      top: 80px;
      margin-top: 0;
      position: absolute;
      margin-left: 0;
    }
  }
}