.btn{
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-color: transparent;
  background-image: none;
  outline: none;
  white-space: nowrap;
  padding: 19px 30px 18px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  border-radius: 8px;
  font-family: $primary-font;
  color: #fff;
  text-decoration: none;
  transition: background-color $primary-transition;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:active {
    outline: 0;
    background-image: none;
  }

  @include mq(1024){
    padding: 27px 30px 26px;
    font-size: 22px;
  }

  //BEGIN mods
  &_violet{
    background-color: #514cc8;
    box-shadow: 0 6px #514cc8*0.75;

    &:hover,
    &:focus{
      background-color: #514cc8*0.9;
    }

    @include mq(1024){
      box-shadow: 0 8px #514cc8*0.75;
    }

  }
  //END mods


}