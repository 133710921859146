.tiles {

  @include mq(768){
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }


  //BEGIN mods
  &_services {
    .tiles {

      &__item {
        &:not(:first-child) {
          margin-top: 40px;
        }
      }

    }

    @include mq(768){
      margin: -30px 0;
      .tiles{
        &__item {
          flex-shrink: 0;
          width: 220px;
          margin: 30px 60px;
          &:not(:first-child) {
            margin-top: 30px;
          }
        }
      }
    }

    @include mq(1024){
      margin: -30px -20px;
      flex-wrap: nowrap;
      justify-content: space-between;

      .tiles {
        &__item {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
  //END mods

}