$text-color: #000;

$main: #09f0ed;
$black: #060017;
$black-light: #24242B;


// fonts
$primary-font: 'Gotham Pro', sans-serif;

$img: '../img/';
$font: '../fonts/';

// header size

$header-height: 97px;


$primary-transition: 0.32s ease-out;
$fast-transition: 0.23s cubic-bezier(.165,.84,.44,1);
