.testimonial-card{
  background-color: #ffffff;
  padding: 0 15px;

  &__blockquote{
    font-weight: 400;
    color: $black-light;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;

    padding-top: 46px;
    position: relative;

    &::before{
      content: '';
      position: absolute;
      left: 0;
      margin: auto;
      top: 0;
      width: 70px;
      height: 24px;

      background-image: url($img + 'testimonial-card/quotes.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% auto;
    }

  }

  &__author{
    margin-top: 20px;
  }

  @include mq(1024){
    &__blockquote{
      font-size: 22px;
      padding-top: 39px+28px;

      &::before{
        width: 82px;
        height: 28px;
      }
    }

    &__author{
      margin-top: 30px;
    }
  }

}