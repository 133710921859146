/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@font-face { font-family: 'Gotham Pro'; font-style: normal; font-weight: 400; src: local("Gotham Pro"), url("../fonts/GothamProregular/GothamProReg.woff") format("woff"), url("../fonts/GothamProregular/GothamProReg.ttf") format("ttf"); }

@font-face { font-family: 'Gotham Pro'; font-style: normal; font-weight: 600; src: local("Gotham Pro Bold"), url("../fonts/GothamProbold/GothamProBold.woff") format("woff"), url("../fonts/GothamProbold/GothamProBold.ttf") format("ttf"); }

/* --------------------------------

Primary style

-------------------------------- */
*, *::after, *::before { box-sizing: border-box; -webkit-tap-highlight-color: transparent; }

html { /*background-color: #ac00e6;*/ }

body { font-size: 16px; font-family: "Gotham Pro", sans-serif; background-color: #fff; color: #000; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

html.noscroll, html.noscroll body { overflow: hidden !important; height: 100% !important; }

:focus { outline: 0; }

p, h1, h2, h3, h4, ul { margin: 0; }

ul { padding: 0; }

a { -webkit-transition: color, opacity 0.32s ease-out; -moz-transition: color, opacity 0.32s ease-out; transition: color, opacity 0.32s ease-out; }

img { max-width: 100%; height: auto; }

/* --------------------------------

Main content

-------------------------------- */
@media only screen and (max-width: 1023px) { .mobile-hidden { display: none !important; } }

@media only screen and (min-width: 1024px) { .desktop-hidden { display: none !important; } }

textarea { resize: none; }

/* xkeyframes */
@-webkit-keyframes fadeInDown { 0% { opacity: 0;
    transform: translateY(-100%); }
  100% { opacity: 1;
    transform: none; } }

@-moz-keyframes fadeInDown { 0% { opacity: 0;
    transform: translateY(-100%); }
  100% { opacity: 1;
    transform: none; } }

@keyframes fadeInDown { 0% { opacity: 0;
    transform: translateY(-100%); }
  100% { opacity: 1;
    transform: none; } }

@-webkit-keyframes fadeInUp { 0% { opacity: 0;
    transform: translateY(50%); }
  100% { opacity: 1;
    transform: none; } }

@-moz-keyframes fadeInUp { 0% { opacity: 0;
    transform: translateY(50%); }
  100% { opacity: 1;
    transform: none; } }

@keyframes fadeInUp { 0% { opacity: 0;
    transform: translateY(50%); }
  100% { opacity: 1;
    transform: none; } }

@-webkit-keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@-moz-keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@-webkit-keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }

@-moz-keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }

@keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }

.row { margin: auto; width: 100%; max-width: 1288px; padding: 0 15px; position: relative; }

@media only screen and (min-width: 1024px) { .row { padding: 0 20px; } }

.m-container { overflow: hidden; }

.btn { position: relative; display: inline-block; text-align: center; vertical-align: middle; touch-action: manipulation; cursor: pointer; background-color: transparent; background-image: none; outline: none; white-space: nowrap; padding: 19px 30px 18px; font-size: 18px; line-height: 1.2; font-weight: 600; border-radius: 8px; font-family: "Gotham Pro", sans-serif; color: #fff; text-decoration: none; transition: background-color 0.32s ease-out; }

.btn:focus, .btn:hover { text-decoration: none; }

.btn:active { outline: 0; background-image: none; }

@media only screen and (min-width: 1024px) { .btn { padding: 27px 30px 26px; font-size: 22px; } }

.btn_violet { background-color: #514cc8; box-shadow: 0 6px #3d3996; }

.btn_violet:hover, .btn_violet:focus { background-color: #4944b4; }

@media only screen and (min-width: 1024px) { .btn_violet { box-shadow: 0 8px #3d3996; } }

.fade { opacity: 0; transition: opacity .15s linear; }

.fade.in { opacity: 1; }

/*********************MODAL*********************/
.modal { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1050; display: none; overflow: hidden; -webkit-overflow-scrolling: touch; outline: 0; }

.modal-open { overflow: hidden; }

.modal-open .modal { overflow-x: hidden; overflow-y: auto; }

.modal-dialog { position: relative; width: 95%; max-width: 820px; margin: 25px auto; }

.modal-content { position: relative; border-radius: 8px; background-color: #fff; box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15); -webkit-background-clip: padding-box; background-clip: padding-box; outline: 0; }

.modal-backdrop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1040; background-color: #060017; }

.modal-backdrop.fade { opacity: 0; filter: alpha(opacity=0); }

.modal-backdrop.in { opacity: 0.75; filter: alpha(opacity=50); }

.modal-header { position: relative; }

.modal-header .close { position: absolute; top: 16px; right: 16px; display: inline-block; width: 16px; height: 16px; border: none; transition: opacity 0.23s cubic-bezier(0.165, 0.84, 0.44, 1); opacity: .45; background: url("../img/ico-close.svg"); background-position: center; background-repeat: no-repeat; background-size: 100% auto; }

.modal-header .close:hover { opacity: 1; }

@media only screen and (min-width: 1024px) { .modal-header .close { top: 21px; right: 21px; width: 20px; height: 20px; } }

.modal.fade .modal-dialog { transform: translate(0, -25%); transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }

.modal.in .modal-dialog { transform: translate(0, 0); }

/*********************COLLAPSE*********************/
.collapse { display: none; }

.collapse.in { display: block; }

.collapsing { position: relative; height: 0; overflow: hidden; transition-property: height, visibility; transition-duration: 0.35s; transition-timing-function: ease; }

/*********************TAB*********************/
.tab-content > .tab-pane { display: none; }

.tab-content > .active { display: block; }

.m-header { width: 100%; position: absolute; left: 0; top: 0; z-index: 100; background-color: transparent; overflow: hidden; }

.m-header::before { content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; transform: translateY(-100%); transition: transform 0.32s ease-out; background-color: #060017; z-index: 0; }

.m-header__container { width: 100%; max-width: 1288px; margin-left: auto; margin-right: auto; display: flex; align-items: center; justify-content: space-between; padding: 0 20px; height: 97px; }

.m-header__logo { display: block; max-width: 240px; }

.m-header__menu-toggle { display: flex; align-items: center; color: #fff; font-size: 18px; font-weight: bold; }

.m-header__menu-ico { position: relative; width: 31px; height: 30px; background: none; border: none; cursor: pointer; margin-left: 8px; }

.m-header__menu-ico span { position: absolute; left: 0; width: 100%; height: 4px; top: 50%; bottom: auto; transform: translateY(-50%); background-color: #09f0ed; border-radius: 4px; }

.m-header__menu-ico span::before, .m-header__menu-ico span::after { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #09f0ed; -webkit-backface-visibility: hidden; backface-visibility: hidden; transition: transform 0.32s ease-out; border-radius: 4px; }

.m-header__menu-ico span::before { transform: translateY(-10px); }

.m-header__menu-ico span::after { transform: translateY(10px); }

.m-header__menustate { display: none; }

.m-header__nav a { display: inline-block; position: relative; font-weight: 600; color: #fff; text-decoration: none; font-size: 22px; line-height: 1.3; }

.m-header__nav a::after { content: ''; position: absolute; left: 0; bottom: 0; width: 100%; height: 4px; border-radius: 4px; background-color: #09f0ed; transform: translateY(13px); opacity: 0; transition-property: transform, opacity; transition: 0.32s ease-out; }

.m-header__nav a:hover::after, .m-header__nav a:focus::after { transform: translateY(3px); opacity: 1; }

.m-header__nav .chacked a { cursor: default; }

.m-header__nav .chacked a::after { transform: translateY(3px); opacity: 1; }

@media only screen and (max-width: 1023px) { .m-header__nav { text-align: center; z-index: 10; position: absolute; top: 97px; left: 0; overflow: scroll; margin: 0; height: 100%; width: 100%; -webkit-overflow-scrolling: touch; padding: 10px 0 100px; visibility: hidden; }
  .m-header__nav ul { height: auto; width: 100%; margin: auto; }
  .m-header__nav li { width: 100%; text-align: center; opacity: 0; transform: scale(1.1) translate(-14px, -30px); }
  .m-header__nav li:not(:first-child) { margin-top: 30px; }
  .m-header__nav li:last-child { transform: none; }
  .m-header__nav a { font-size: 32px; }
  .m-header__nav-lang { /*position: absolute; bottom: 120px;*/ padding-top: 20px; }
  .m-header__nav-lang li { display: inline-block; width: auto; transform: none; }
  .m-header__nav-lang li:not(:first-child) { margin-left: 50px; margin-top: 0; }
  .m-header__nav-lang a { font-size: 18px; }
  .m-header__logo { width: 57px; overflow: hidden; }
  .m-header__logo svg { max-width: none; width: 320px; } }

@media only screen and (min-width: 1024px) { .m-header__container { transform: translate3d(0, 0, 0); }
  .m-header__nav { padding-left: 50px; flex-shrink: 0; }
  .m-header__nav li { display: inline-block; padding: 4px 16px; border-radius: 16px; transition: background-color 0.4s ease-out; }
  .m-header__nav li:not(:first-child) { margin-left: 10px; }
  .m-header__nav li a { font-size: 18px; color: #fff; }
  .m-header__nav .active { background-color: #09f0ed; }
  .m-header__nav .active a::after { display: none; }
  .m-header__nav-lang { margin-left: 30px; }
  .m-header__nav-lang li { padding-left: 0; padding-right: 0; }
  .m-header__nav-lang li:not(:first-child) { margin-left: 25px; } }

.m-header_sticky { position: fixed; /*@supports (position: sticky) { position: sticky; }*/ background-color: #fff; box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25); animation: fadeInDown 0.7s; }

.m-header_sticky .m-header__container { height: 75px; }

.m-header_sticky .m-header__logo .word-icon { fill: #31313A; }

.m-header_sticky .m-header__logo .word-software { fill: #443EC7; }

@media only screen and (min-width: 1024px) { .m-header_sticky .m-header__nav li a { color: #24242B; } }

@media only screen and (max-width: 1023px) { .m-header__menustate:checked ~ .m-header { height: 100%; }
  .m-header__menustate:checked ~ .m-header::before { transform: translateY(0); }
  .m-header__menustate:checked ~ .m-header .m-header__nav { visibility: visible; }
  .m-header__menustate:checked ~ .m-header .m-header__nav li { transition: opacity 0.32s ease-out, transform 0.32s ease-out; transition-delay: 200ms, 200ms; transform: none; opacity: 1; }
  .m-header__menustate:checked ~ .m-header .m-header__nav li:nth-child(2) { transition-delay: 250ms, 250ms; }
  .m-header__menustate:checked ~ .m-header .m-header__nav li:nth-child(3) { transition-delay: 300ms, 300ms; }
  .m-header__menustate:checked ~ .m-header .m-header__nav li:nth-child(4) { transition-delay: 350ms, 350ms; }
  .m-header__menustate:checked ~ .m-header .m-header__nav li:nth-child(5) { transition-delay: 400ms, 400ms; }
  .m-header__menustate:checked ~ .m-header .m-header__menu-ico span { background-color: transparent; }
  .m-header__menustate:checked ~ .m-header .m-header__menu-ico span::before { transform: rotate(-45deg); }
  .m-header__menustate:checked ~ .m-header .m-header__menu-ico span::after { transform: rotate(45deg); } }

.m-footer { padding: 32px 0; background-color: #24242b; overflow: hidden; }

.m-footer__flex-wrap:not(:first-child) { margin-top: 24px; }

.m-footer__logo { max-width: 208px; }

.m-footer__email { display: inline-block; font-size: 18px; font-weight: 600; line-height: 1.45; text-decoration: none; color: rgba(255, 255, 255, 0.8); border-bottom: solid 1px rgba(255, 255, 255, 0.15); transition: border 0.32s ease-out; }

.m-footer__email:focus, .m-footer__email:hover { border-color: rgba(255, 255, 255, 0.8); }

.m-footer__copyrights { font-size: 14px; line-height: 1.4; text-align: center; color: #fff; }

@media only screen and (min-width: 1024px) { .m-footer__flex-wrap { display: flex; justify-content: space-between; align-items: flex-start; }
  .m-footer__flex-wrap:not(:first-child) { margin-top: 40px; } }

.hero { background-color: #21007F; overflow: hidden; min-height: 447px; position: relative; padding-top: 163px; padding-bottom: 100px; /*background-image: url($img + "hero/oval.svg"); background-size: 250px auto; background-position: 50% 30%; background-repeat: no-repeat;*/ }

.hero__morphin { display: block; position: absolute; width: 320px; height: 200px; left: 0; right: 0; margin: auto; }

.hero__title { text-align: center; font-size: 40px; font-weight: 600; line-height: 1.2; letter-spacing: 0.7px; color: #fff; max-width: 420px; margin-left: auto; margin-right: auto; }

@media only screen and (max-width: 1023px) { .hero { height: 100vh; }
  .hero__morphin { top: 0; bottom: 0; }
  .hero .row { position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto; display: flex; }
  .hero__title { margin: auto; } }

@media only screen and (min-width: 1024px) { .hero { min-height: 617px; padding-top: 237px; padding-bottom: 150px; background-image: none; /*background-size: 480px auto;*/ }
  .hero__title { font-size: 68px; letter-spacing: 1.1px; max-width: 750px; }
  .hero__title_ru { max-width: 820px; }
  .hero__morphin { top: 5%; width: 100%; height: 90%; max-width: 1280px; } }

.section { background-color: #fff; padding: 30px 0 35px; }

.section__heading { margin-bottom: 25px; }

.section__title { color: #24242B; font-weight: 600; font-size: 38px; text-align: center; }

.section__text { max-width: 780px; margin-left: auto; margin-right: auto; }

.section_bg_blue { background-color: #09f0ed; background-image: url("../img/section/bg_blue_top.svg"), url("../img/section/bg_blue_bottom.svg"); background-repeat: no-repeat; background-position: 50% 0, 50% 100%; background-size: 200% auto; }

@media only screen and (min-width: 1024px) { .section_bg_blue { background-size: 101% auto; } }

.section_bg_yellow { background-color: #ffd500; background-image: url("../img/section/bg_yellow_top.svg"), url("../img/section/bg_yellow_bottom.svg"); background-repeat: no-repeat; background-position: 50% 0, 50% 100%; background-size: 200% auto; }

@media only screen and (min-width: 1024px) { .section_bg_yellow { background-size: 101% auto; } }

.section_bg_grey { background-color: #f9f9f9; background-image: url("../img/section/bg_grey.svg"); background-repeat: no-repeat; background-position: 50% 50%; background-size: 200% auto; }

@media only screen and (min-width: 1024px) { .section_bg_grey { background-size: cover; } }

@media only screen and (max-width: 1023px) { .section_reviews .section__heading { display: none; } }

@media only screen and (min-width: 1024px) { .section { padding: 100px 0; }
  .section__heading { margin-bottom: 55px; }
  .section__title { font-size: 62px; } }

@media only screen and (min-width: 768px) { .tiles { display: flex; flex-wrap: wrap; align-items: flex-start; justify-content: center; } }

.tiles_services .tiles__item:not(:first-child) { margin-top: 40px; }

@media only screen and (min-width: 768px) { .tiles_services { margin: -30px 0; }
  .tiles_services .tiles__item { flex-shrink: 0; width: 220px; margin: 30px 60px; }
  .tiles_services .tiles__item:not(:first-child) { margin-top: 30px; } }

@media only screen and (min-width: 1024px) { .tiles_services { margin: -30px -20px; flex-wrap: nowrap; justify-content: space-between; }
  .tiles_services .tiles__item { margin-left: 20px; margin-right: 20px; } }

.link { display: inline-block; position: relative; text-decoration: none; }

.link_details { font-size: 22px; line-height: 1.2; font-weight: 600; color: #443ec7; border-bottom: solid 1px rgba(68, 62, 199, 0.25); transition: border 0.32s ease-out; }

.link_details:hover, .link_details:focus { border-color: #443ec7; text-decoration: none; }

.user-preview { display: flex; justify-content: flex-start; align-items: center; text-decoration: none; }

.user-preview__img { width: 48px; height: 48px; margin-right: 16px; flex-shrink: 0; overflow: hidden; border-radius: 50%; }

.user-preview__name { font-size: 18px; line-height: 1.33; font-weight: 600; text-align: left; color: #24242B; }

@media only screen and (min-width: 1024px) { .user-preview__img { width: 56px; height: 56px; }
  .user-preview__name { font-size: 22px; } }

.testimonial-card { background-color: #ffffff; padding: 0 15px; }

.testimonial-card__blockquote { font-weight: 400; color: #24242B; font-size: 16px; line-height: 1.5; text-align: left; padding-top: 46px; position: relative; }

.testimonial-card__blockquote::before { content: ''; position: absolute; left: 0; margin: auto; top: 0; width: 70px; height: 24px; background-image: url("../img/testimonial-card/quotes.svg"); background-repeat: no-repeat; background-position: 50%; background-size: 100% auto; }

.testimonial-card__author { margin-top: 20px; }

@media only screen and (min-width: 1024px) { .testimonial-card__blockquote { font-size: 22px; padding-top: 67px; }
  .testimonial-card__blockquote::before { width: 82px; height: 28px; }
  .testimonial-card__author { margin-top: 30px; } }

.slider_testimonials { margin: 0 -15px 40px; }

.slider_testimonials .slick-dots { display: block; width: 100%; text-align: center; position: absolute; bottom: -40px; z-index: 3; }

.slider_testimonials .slick-dots li { display: inline-block; }

.slider_testimonials .slick-dots li:not(:first-child) { margin-left: 8px; }

.slider_testimonials .slick-dots li button { font-size: 0; line-height: 0; padding: 0; display: block; width: 8px; height: 8px; background-color: #eaeaeb; border: none; border-radius: 50%; }

.slider_testimonials .slick-dots li.slick-active button { background-color: #443ec7; }

.slider_testimonials .slider__item { display: flex; }

.slider_testimonials .slider__item .testimonial-card { max-width: 340px; margin-left: auto; margin-right: auto; }

@media only screen and (min-width: 1024px) { .slider_testimonials .slick-dots { bottom: -77px; }
  .slider_testimonials .slick-dots li:not(:first-child) { margin-left: 16px; }
  .slider_testimonials .slick-dots li button { width: 16px; height: 16px; border: 4px solid #eaeaeb; }
  .slider_testimonials .slick-dots li.slick-active button { background-color: #fff; border-color: #443ec7; }
  .slider_testimonials .slider__item { position: relative; }
  .slider_testimonials .slider__item::after { content: ''; position: absolute; right: -2px; top: 54px; width: 4px; height: 200px; background-color: rgba(151, 151, 151, 0.15); border-radius: 4px; } }

.contact-form { padding: 24px; background-color: #fff; box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.2); border-radius: 8px; }

.contact-form__heading { margin-bottom: 24px; }

.contact-form__title { color: #24242B; font-weight: 600; font-size: 38px; line-height: 1.1; text-align: center; }

.contact-form__row:not(:first-child), .contact-form__col:not(:first-child) { margin-top: 16px; }

.contact-form__btn-submit { width: 100%; }

@media only screen and (min-width: 1024px) { .contact-form { padding: 59px 64px 64px; }
  .contact-form__row_col-2 { display: flex; align-items: flex-start; justify-content: space-between; }
  .contact-form__title { font-size: 48px; }
  .contact-form__row:not(:first-child) { margin-top: 24px; }
  .contact-form__col { width: 234px; }
  .contact-form__col:not(:first-child) { margin-top: 0; } }

.form-group input[type='text'], .form-group input[type='email'], .form-group input[type='tel'], .form-group textarea { font-family: "Gotham Pro", sans-serif; width: 100%; background-color: #efeff4; padding: 16px 24px; font-size: 18px; line-height: 1.3; border: solid 3px transparent; color: #1b1b21; border-radius: 4px; appearance: none; transition-property: background-color, border; transition: 0.32s ease-out; /*&:invalid{ border-color: #FFA1A1; }*/ }

.form-group input[type='text']::-webkit-input-placeholder, .form-group input[type='email']::-webkit-input-placeholder, .form-group input[type='tel']::-webkit-input-placeholder, .form-group textarea::-webkit-input-placeholder { color: rgba(27, 27, 33, 0.6); }

.form-group input[type='text']::-moz-placeholder, .form-group input[type='email']::-moz-placeholder, .form-group input[type='tel']::-moz-placeholder, .form-group textarea::-moz-placeholder { color: rgba(27, 27, 33, 0.6); }

.form-group input[type='text']:-moz-placeholder, .form-group input[type='email']:-moz-placeholder, .form-group input[type='tel']:-moz-placeholder, .form-group textarea:-moz-placeholder { color: rgba(27, 27, 33, 0.6); }

.form-group input[type='text']:-ms-input-placeholder, .form-group input[type='email']:-ms-input-placeholder, .form-group input[type='tel']:-ms-input-placeholder, .form-group textarea:-ms-input-placeholder { color: rgba(27, 27, 33, 0.6); }

.form-group input[type='text']:hover, .form-group input[type='text']:focus, .form-group input[type='text']:valid, .form-group input[type='email']:hover, .form-group input[type='email']:focus, .form-group input[type='email']:valid, .form-group input[type='tel']:hover, .form-group input[type='tel']:focus, .form-group input[type='tel']:valid, .form-group textarea:hover, .form-group textarea:focus, .form-group textarea:valid { background-color: transparent; border-color: #C8C8F1; }

@media only screen and (min-width: 1024px) { .form-group input[type='text'], .form-group input[type='email'], .form-group input[type='tel'], .form-group textarea { font-size: 22px; line-height: 1.2; } }

.service { display: block; text-align: center; text-decoration: none; }

.service:focus .link_details, .service:hover .link_details { border-color: #443ec7; }

.service:focus .service__img, .service:hover .service__img { transform: scale(1.1); }

.service:focus .service__title, .service:hover .service__title { transform: scale(1.04); }

.service__img { display: inline-block; max-width: 175px; min-height: 115px; text-align: center; margin-left: auto; margin-right: auto; transition: transform 0.32s ease-out; }

.service__text { text-align: center; max-width: 220px; margin-left: auto; margin-right: auto; }

.service__title { font-weight: 600; font-size: 28px; line-height: 1.25; margin-top: 20px; color: #24242B; transition: transform 0.32s ease-out; }

.service__descr { font-size: 18px; line-height: 1.33; color: #4f4f4f; margin-top: 6px; }

.service__link { margin-top: 10px; display: block; }

.rich-text { color: #24242B; }

.rich-text_tc { text-align: center; }

.rich-text h1, .rich-text h2, .rich-text h3, .rich-text h4, .rich-text h5, .rich-text h6 { margin-bottom: .5em; font-weight: 600; line-height: 1.1; }

.rich-text h1:not(:first-child), .rich-text h2:not(:first-child), .rich-text h3:not(:first-child), .rich-text h4:not(:first-child), .rich-text h5:not(:first-child), .rich-text h6:not(:first-child) { margin-top: 1.15em; }

.rich-text h2 { font-size: 28px; }

.rich-text h3 { font-size: 24px; }

.rich-text p, .rich-text ol, .rich-text ul { font-size: 16px; font-weight: 400; line-height: 1.5; margin-bottom: 1em; }

.rich-text p:last-child, .rich-text ol:last-child, .rich-text ul:last-child { margin-bottom: 0; }

.rich-text li { position: relative; margin-bottom: 0.45em; padding-left: 1em; }

.rich-text li::before { content: ''; width: 6px; height: 6px; background-color: #FFD500; position: absolute; left: 0; top: .5em; border-radius: 50%; }

.rich-text ul.list-none li { padding-left: 0; }

.rich-text ul.list-none li::before { display: none; }

@media only screen and (min-width: 1024px) { .rich-text h2 { font-size: 38px; }
  .rich-text h3 { font-size: 28px; }
  .rich-text p, .rich-text ol, .rich-text ul { font-size: 22px; }
  .rich-text li::before { width: 8px; height: 8px; } }

.career-card { display: block; background-color: #fff; padding: 24px; box-shadow: 0 2px 20px 0 rgba(35, 34, 31, 0.2); border-radius: 4px; text-decoration: none; transition: box-shadow 0.32s ease-out, transform 0.32s ease-out; }

.career-card:focus, .career-card:hover { box-shadow: 0 2px 25px 5px rgba(35, 34, 31, 0.25); transform: scale(1.05); }

.career-card__title { text-align: center; font-size: 28px; line-height: 1.2; font-weight: 600; color: #24242B; min-height: 64px; }

.career-card__img { text-align: center; margin-top: 30px; margin-left: auto; margin-right: auto; max-width: 100px; overflow: hidden; line-height: 0; display: block; }

@media only screen and (min-width: 1024px) { .career-card__title { font-size: 32px; line-height: 1.25; text-align: left; }
  .career-card__img { margin-right: 0; } }

.careers__item { margin-left: auto; margin-right: auto; max-width: 290px; }

.careers__item:not(:first-child) { margin-top: 16px; }

.careers__item.fadeInUp { animation: fadeInUp 0.7s; }

.careers__btn-more { margin-top: 24px; text-align: center; }

.careers__btn-more .btn { max-width: 290px; width: 100%; }

@media only screen and (min-width: 1024px) { .careers__tiles { display: flex; flex-wrap: wrap; align-items: stretch; justify-content: center; margin: -15px; }
  .careers__tiles .career-card { height: 100%; }
  .careers__item { width: 33.3%; max-width: 428px; flex-shrink: 0; padding: 15px; margin-left: 0; margin-right: 0; }
  .careers__item:not(:first-child) { margin-top: 0; }
  .careers__btn-more { margin-top: 42px; }
  .careers__btn-more .btn { max-width: 380px; } }

.technologies { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; max-width: 280px; margin-left: auto; margin-right: auto; }

.technologies__item { padding: 11px 20px; max-width: 140px; }

@media only screen and (min-width: 768px) { .technologies { max-width: 560px; } }

@media only screen and (min-width: 1024px) { .technologies { max-width: 1120px; }
  .technologies__item { padding: 11px 15px; max-width: 280px; } }

.section-map { position: relative; }

.section-map__map { width: 100%; height: 350px; }

.section-map__form { margin-top: -63px; padding-bottom: 32px; width: 100%; max-width: 620px; margin-left: auto; margin-right: auto; }

@media only screen and (min-width: 1024px) { .section-map .row { position: absolute; top: 0; left: 0; right: 0; }
  .section-map__map { height: 744px; }
  .section-map__form { top: 80px; margin-top: 0; position: absolute; margin-left: 0; } }

.contacts { text-align: center; color: #24242b; font-size: 16px; line-height: 1.6; }

.contacts__col:not(:first-child) { margin-top: 42px; }

.contacts__col_email { margin-top: 33px; }

.contacts__address, .contacts__tel { position: relative; }

.contacts__address::before, .contacts__tel::before { content: ''; position: absolute; top: 0; left: 0; right: 0; margin: auto; background-size: 100% auto; background-repeat: no-repeat; background-position: 50% 0; }

.contacts__address { padding-top: 62px; }

.contacts__address::before { background-image: url("../img/contacts/address.svg"); width: 40px; height: 48px; }

.contacts__tel { padding-top: 57px; }

.contacts__tel::before { background-image: url("../img/contacts/tel.svg"); width: 43px; height: 45px; }

.contacts__tel a { text-decoration: none; color: #24242b; }

.contacts__tel a:focus, .contacts__tel a:hover { text-decoration: underline; }

.contacts__email { display: inline-block; }

.contacts__email:focus, .contacts__email:hover { border-color: #514cc8; }

@media only screen and (min-width: 1024px) { .contacts { display: flex; align-items: center; justify-content: flex-start; text-align: left; }
  .contacts__col { border-right: solid 4px #EFEFEF; display: flex; padding: 0 15px; }
  .contacts__col:not(:first-child) { margin-top: 0; }
  .contacts__col:nth-child(1) { width: 35%; padding-left: 0; }
  .contacts__col:nth-child(2) { width: 36%; }
  .contacts__col:nth-child(3) { width: 29%; border: none; }
  .contacts__address, .contacts__tel { padding-top: 0; font-size: 22px; }
  .contacts__address::before, .contacts__tel::before { left: 0; right: auto; }
  .contacts__address { padding-left: 78px; }
  .contacts__address::before { width: 52px; height: 62px; }
  .contacts__tel { padding-left: 77px; margin-left: auto; margin-right: auto; }
  .contacts__tel::before { top: 5px; width: 50px; height: 52px; }
  .contacts__email { margin-left: auto; margin-right: auto; } }

.social { display: flex; justify-content: center; align-items: center; margin-left: -19px; margin-right: -19px; }

.social li { display: inline-block; margin: 0 19px; }

.social__icon { display: block; width: 21px; height: 24px; text-decoration: none; transition: transform 0.23s cubic-bezier(0.165, 0.84, 0.44, 1); }

.social__icon svg { max-width: 100%; max-height: 100%; fill: #fff; }

.social__icon:focus, .social__icon:hover { transform: scale(1.2); }

.modal_note .modal-header { padding: 56px 10px 20px; }

.modal_note .modal-title { font-size: 28px; font-weight: 600; line-height: 1.14; color: #24242B; text-align: left; }

.modal_note .modal-body { padding: 0 15px 40px; }

@media only screen and (min-width: 1024px) { .modal_note .modal-header { padding: 60px 60px 40px; }
  .modal_note .modal-title { font-size: 48px; }
  .modal_note .modal-body { padding: 0 60px 60px; } }
