.technologies{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;

  //margin: -11px 0;

  &__item{
    padding: 11px 20px;
    max-width: 140px;
  }

  @include mq(768){
    max-width: 560px;
  }

  @include mq(1024){
    max-width: 1120px;

    &__item{
      padding: 11px 15px;
      max-width: 280px;
    }

  }
}