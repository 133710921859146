.contacts {
  text-align: center;
  color: #24242b;
  font-size: 16px;
  line-height: 1.6;

  &__col {
    &:not(:first-child) {
      margin-top: 42px;
    }

    &_email {
      margin-top: 33px;
    }
  }

  &__address,
  &__tel {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 50% 0;
    }
  }

  &__address {
    padding-top: 48px+14px;

    &::before {
      background-image: url($img + 'contacts/address.svg');
      width: 40px;
      height: 48px;
    }
  }

  &__tel {
    padding-top: 43px+14px;

    &::before {
      background-image: url($img + 'contacts/tel.svg');
      width: 43px;
      height: 45px;
    }

    a {
      text-decoration: none;
      color: #24242b;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__email {
    display: inline-block;

    &:focus,
    &:hover {
      border-color: #514cc8;
    }
  }

  @include mq(1024) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    &__col {
      border-right: solid 4px #EFEFEF;
      display: flex;
      padding: 0 15px;

      &:not(:first-child){
        margin-top: 0;
      }
      &:nth-child(1) {
        width: 35%;
        padding-left: 0;
      }
      &:nth-child(2) {
        width: 36%;
      }
      &:nth-child(3) {
        width: 29%;
        border: none;
      }
    }

    &__address,
    &__tel {
      padding-top: 0;
      font-size: 22px;

      &::before {
        left: 0;
        right: auto;
      }
    }

    &__address{
      padding-left: 52px+26px;

      &::before{
        width: 52px;
        height: 62px;
      }
    }

    &__tel{
      padding-left: 51px+26px;
      margin-left: auto;
      margin-right: auto;

      &::before{
        top: 5px;
        width: 50px;
        height: 52px;
      }
    }

    &__email{
      margin-left: auto;
      margin-right: auto;
    }

  }

}