.careers{

  &__item{
    margin-left: auto;
    margin-right: auto;
    max-width: 290px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &.fadeInUp{
      animation: fadeInUp 0.7s;
    }
  }

  &__btn-more{
    margin-top: 24px;
    text-align: center;

    .btn{
      max-width: 290px;
      width: 100%;
    }
  }

  @include mq(1024){
    &__tiles{
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      margin: -15px;

      .career-card{
        height: 100%;
      }
    }

    &__item{
      width: 33.3%;
      max-width: 428px;
      flex-shrink: 0;
      padding: 15px;
      margin-left: 0;
      margin-right: 0;
      &:not(:first-child) {
        margin-top: 0;
      }
    }

    &__btn-more{
      margin-top: 42px;

      .btn{
        max-width: 380px;
      }
    }
  }
}