.service{
  display: block;
  text-align: center;
  text-decoration: none;

  &:focus,
  &:hover{
    .link_details{
      border-color: #443ec7;
    }

    .service__img
    {
      transform: scale(1.1);
    }

    .service__title{
      transform: scale(1.04);
    }
  }

  &__img{
    display: inline-block;
    max-width: 175px;
    min-height: 115px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    transition: transform $primary-transition;
  }

  &__text{
    text-align: center;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title{
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    margin-top: 20px;
    color: $black-light;
    transition: transform $primary-transition;
  }

  &__descr{
    font-size: 18px;
    line-height: 1.33;
    color: #4f4f4f;
    margin-top: 6px;
  }

  &__link{
    margin-top: 10px;
    display: block;
  }

  //BEGIN mods

  //END mods

}