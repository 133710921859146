.career-card {
  display: block;
  background-color: #fff;
  padding: 24px;
  box-shadow: 0 2px 20px 0 rgba(35, 34, 31, 0.2);
  border-radius: 4px;
  text-decoration: none;
  transition: box-shadow $primary-transition, transform $primary-transition;

  &:focus,
  &:hover {
    box-shadow: 0 2px 25px 5px rgba(35,34,31,.25);
    transform: scale(1.05);
  }

  &__title {
    text-align: center;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 600;
    color: $black-light;
    min-height: 64px;
  }

  &__img {
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100px;
    overflow: hidden;
    line-height: 0;
    display: block;
  }

  @include mq(1024) {
    &__title{
      font-size: 32px;
      line-height: 1.25;
      text-align: left;
    }

    &__img{
      margin-right: 0;
    }
  }

}