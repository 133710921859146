.link{
  display: inline-block;
  position: relative;
  text-decoration: none;


  //BEGIN mods

  &_details{
    font-size: 22px;
    line-height: 1.2;
    font-weight: 600;
    color: #443ec7;
    border-bottom: solid 1px rgba(#443ec7, .25);
    transition: border $primary-transition;


    &:hover,
    &:focus{
      border-color: #443ec7;
      text-decoration: none;
    }

  }
  //END mods

}