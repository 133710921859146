.hero {
  background-color: #21007F;
  overflow: hidden;
  min-height: 350px + $header-height;
  position: relative;

  padding-top: 66px + $header-height;
  padding-bottom: 100px;

  /*background-image: url($img + "hero/oval.svg");
  background-size: 250px auto;
  background-position: 50% 30%;
  background-repeat: no-repeat;*/

  &__morphin {
    display: block;
    position: absolute;
    width: 320px;
    height: 200px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__title {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.7px;
    color: #fff;

    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mq_max(1023) {
    height: 100vh;

    &__morphin{
      top: 0;
      bottom: 0;
    }

    .row{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
    }

    &__title{
      margin: auto;
    }
  }

  @include mq(1024) {
    min-height: 520px + $header-height;
    padding-top: 140px + $header-height;
    padding-bottom: 150px;
    background-image: none;
    /*background-size: 480px auto;*/

    &__title {
      font-size: 68px;
      letter-spacing: 1.1px;
      max-width: 750px;

      &_ru {
        max-width: 820px;
      }
    }

    &__morphin {
      top: 5%;
      width: 100%;
      height: 90%;
      max-width: 1280px;
    }

  }

}