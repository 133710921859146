.form-group {

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    font-family: $primary-font;
    width: 100%;
    background-color: #efeff4;
    padding: 16px 24px;
    font-size: 18px;
    line-height: 1.3;
    border: solid 3px transparent;
    color: #1b1b21;
    border-radius: 4px;
    appearance: none;
    transition-property: background-color, border;
    transition: $primary-transition;

    @include placeholder() {
      color: rgba(#1b1b21, 0.6);
    }

    &:hover,
    &:focus,
    &:valid {
      background-color: transparent;
      border-color: #C8C8F1;
    }

    /*&:invalid{
      border-color: #FFA1A1;
    }*/
  }

  @include mq(1024){
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    textarea {
      font-size: 22px;
      line-height: 1.2;
    }
  }

}